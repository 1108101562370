<template>
  <v-card flat>
      <v-toolbar flat color="info">
            <v-toolbar-title>
                What is this site all about?
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols='12' class="title">
                    So what random corner of the Internet did I end up in?
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="subtitle-1">
                    Welcome to a facet of the <a href="http://www.welikedota.com/" class="secondary--text">WeLikeDota</a> Community; home of the Internet's <i>casual</i> Dota 2 podcast.
                    <br>
                    <br>
                    Essentially this site serves up our Theme Thursday community nights. On Thursdays we try to get some <b>5 vs. 5</b> or even <b>10 vs. 10</b> quirky matches with community members with picks being based around the themes you find here. Occasionally when there are not enough folks around we take our themes into an unranked match so that we can get appropriately pummeled by tryhards.
                    <br>
                    <br>
                    Interested in learning more about WeLikeDota? Click one of the links below!
                </v-col>
            </v-row>
            <v-row justify="space-around" align="center">
                <v-btn href="http://welikedota.com" color="secondary" outlined height="200" width="200">
                    <v-icon left>fas fa-globe</v-icon>
                    WLD on the Web
                </v-btn>
                <v-btn href="https://itunes.apple.com/us/podcast/we-like-dota-podcasts/id799008929" color="secondary" dark outlined height="200" width="200">
                    <v-icon left>fas fa-podcast</v-icon>
                    WLD Podcast
                </v-btn>
                <v-btn href="https://discord.gg/0h2DOuBCUWs9XHZp" color="secondary" dark outlined height="200" width="200">
                    <v-icon left>fab fa-discord</v-icon>
                    WLD Discord
                    </v-btn>
                <v-btn href="https://twitter.com/welikedota" color="secondary" dark outlined height="200" width="200">
                    <v-icon left>fab fa-twitter</v-icon>
                    WLD Twitter
                </v-btn>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
}
</script>